import { DisplayObitWithPosition } from "@/@types/obit-types";
import SubmitObituaryCTA from "@/components/common/SubmitObituaryCTA";
import styled from "@/components/contexts/ThemeProvider/styled";
import { useNewsletterConfig } from "@/hooks/useAppState";
import { Newsletter } from "@gannettdigital/community-hub-components";
import { Hidden } from "@mui/material";
import Ad from "../../atoms/Ad/Ad";
import { ObitCard } from "./ObitCard";

interface ObitTileProps {
  obit: DisplayObitWithPosition;
  isExpandedView?: boolean;
}
const ObitTile = ({ obit, isExpandedView = false }: ObitTileProps) => {
  const newsletterConfig = useNewsletterConfig();

  // 1 ad every 3 obits
  const showInlineAd = (obit.position + 1) % 3 === 0;
  // show Place Obit at the third obit card
  const showPlaceObitCard = obit.position === 2;

  const plc = showPlaceObitCard ? 'banner-top-section' : 'banner-bottom-section'

  return (
    <div>
      <ObitCard obituary={obit} isExpandedView={isExpandedView} />

      {/* Mobile only since there is no high impact ad */}
      <Hidden mdUp>
        {showInlineAd && (
          <AdsContainer>
            <Ad id={`mobile_inline_${obit.position}`} type="inline" plc={plc} />
          </AdsContainer>
        )}

        {showPlaceObitCard && (
          <>
            <CTAContainer>
              <SubmitObituaryCTA />
            </CTAContainer>
            {Boolean(newsletterConfig) && (
              <CTAContainer>
                <Newsletter
                  title="Obituary Daily Digest"
                  subtitle="Sign up to get obituaries delivered straight to your inbox each day."
                  newsletterConfig={newsletterConfig || undefined}
                />
              </CTAContainer>
            )}
          </>
        )}
      </Hidden>
    </div>
  );
};

export default ObitTile;

const AdsContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginBottom: theme.spacing(6),
}));

const CTAContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(6),
  textAlign: "center",
}));
